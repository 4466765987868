import React from "react";
import classes from "./Loading.module.scss";

export const Loader = () => (
    <div className={classes.loaderContainer}>
        <div className={classes.loader1}></div>
        <div className={classes.loader2}></div>
    </div>
);

const Loading = () => (
    <div className={classes.Loading}>
        <Loader />
    </div>
);

export default Loading;
