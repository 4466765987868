import React from "react";

import classes from "./footer.module.scss";

import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";
import { NavLink } from "react-router-dom";

const Footer = () => {
    return (
        <footer className={classes.footer}>
            <nav>
                <ul className={classes.navList}>
                    <li>
                        <NavLink
                            className={classes.navItem}
                            activeClassName={classes.activeNavItem}
                            exact
                            to="/acerca"
                        >
                            Acerca de
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className={classes.navItem}
                            activeClassName={classes.activeNavItem}
                            exact
                            to="/contacto"
                        >
                            Contacto
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className={classes.navItem}
                            activeClassName={classes.activeNavItem}
                            exact
                            to="/legal"
                        >
                            Legal
                        </NavLink>
                    </li>
                    {/* <li>
                        <NavLink
                            className={classes.navItem}
                            activeClassName={classes.activeNavItem}
                            exact
                            to="/travel"
                        >
                            Travel
                        </NavLink>
                    </li> */}
                    <li>
                        <a
                            className={classes.navItem}
                            activeClassName={classes.activeNavItem}
                            href="https://contrata.tricom.mx"
                        >
                            Contrata
                        </a>
                    </li>
                    <li>
                        <NavLink
                            className={classes.navItem}
                            activeClassName={classes.activeNavItem}
                            exact
                            to="/preguntas"
                        >
                            Preguntas
                        </NavLink>
                    </li>
                    <li>
                        <a
                            className={classes.navItem}
                            activeClassName={classes.activeNavItem}
                            href={"https://portal.tricom.mx"}
                        >
                            Mi Cuenta
                        </a>
                    </li>
                    <ul className={classes.socialLinks}>
                        <li>
                            <a
                                className={classes.socialLink}
                                href="https://www.facebook.com/tricomx.contacto/"
                            >
                                <AiFillFacebook
                                    className={classes.socialIcon}
                                    alt="facebook"
                                />
                            </a>
                        </li>
                        <li>
                            <a
                                className={classes.socialLink}
                                href="https://www.instagram.com/tricom.mx/"
                            >
                                <AiFillInstagram
                                    className={classes.socialIconI}
                                    alt="instagram"
                                />
                            </a>
                        </li>
                    </ul>
                </ul>
            </nav>
            <p className={classes.Copyright}>
                TRICOMX® {new Date().getFullYear()}. Todos los
                derechos reservados.
            </p>
        </footer>
    );
};

export default Footer;
