import React from "react";

const Home = React.lazy(() => import("pages/home"));
const Acerca = React.lazy(() => import("pages/acerca"));
const Contacto = React.lazy(() => import("pages/contacto"));
const Legal = React.lazy(() => import("pages/legal"));
const Privacidad = React.lazy(() => import("pages/privacidad"));
const Terminos = React.lazy(() => import("pages/terminos"));
const Travel = React.lazy(() => import("pages/travel"));
const Preguntas = React.lazy(() => import("pages/preguntas"));

const Premium = React.lazy(() => import("pages/mobile/premium"));
const Esencial = React.lazy(() => import("pages/mobile/esencial"));
const Recarga = React.lazy(() => import("pages/mobile/recarga"));
const Promo = React.lazy(() => import("pages/mobile/promo"));

export const ErrorPage = React.lazy(() => import("../pages/404"));
export const UnauthorizedPage = React.lazy(() =>
  import("../pages/restringido")
);

const routes = [
  {
    path: "/acerca",
    component: Acerca,
  },
  {
    path: "/contacto",
    component: Contacto,
  },
  {
    path: "/legal",
    component: Legal,
  },
  {
    path: "/legal/privacidad",
    component: Privacidad,
  },
  {
    path: "/legal/terminos",
    component: Terminos,
  },
  //travel
  {
    path: "/travel",
    component: Travel,
  },
  //home
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/preguntas",
    component: Preguntas,
  },
  {
    path: "/mobile/promo",
    component: Promo,
  },
  {
    path: "/mobile/portal",
    component: Recarga,
  },
  {
    path: "/mobile/premium",
    component: Premium,
  },
  {
    path: "/mobile",
    component: Esencial,
  },
];

export default routes;
