import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import errorHandler from "./errorHandler";

/*
All general page related actions.
*/

//getAllPlans
export const getPlans = createAsyncThunk(
    "page/getPlans",
    async (arg, { getState, requestId, rejectWithValue }) => {
        const { currentRequestId, loading } = getState().page;
        if (loading !== "pending" || requestId !== currentRequestId) {
            return;
        }
        return await errorHandler(
            Axios.get(`${process.env.REACT_APP_BACKEND_URL}/plan/broadband20`),
            rejectWithValue
        );
    }
);

//send Prospect
export const postProspect = createAsyncThunk(
    "page/postProspect",
    async (prospect, { getState, requestId, rejectWithValue }) => {
        const { currentRequestId, loading } = getState().page;
        if (loading !== "pending" || requestId !== currentRequestId) {
            return;
        }
        return await errorHandler(
            Axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/prospect`,
                prospect
            ),
            rejectWithValue
        );
    }
);
