import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Loading from "../components/UI/Loading/Loading";
import Layout  from "../hoc/layout/layout";
import Routes from "./switch";

function MainNav() {
    return (
        <Router>
            <React.Suspense fallback={<Loading />}>
                <Layout>
                    <Routes />
                </Layout>
            </React.Suspense>
        </Router>
    );
}

export default MainNav;
