import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import classes from "./NavigationItems.module.scss";
import { IoIosArrowDown } from "react-icons/io";

const Dropdown = (props) => {
    const node = useRef();
    const handleClick = (e) => {
        if (node.current.contains(e.target)) {
            // inside click
            props.callbackFromParent(true);
            return;
        }
        // outside click
        props.callbackFromParent(false);
    };
    
    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);
    
    return (
        <ul className={classes.dropDown} ref={node}>
            {props.children}
        </ul>
    );
};

const NavigationItems = (props) => {
    const width = window.innerWidth;
    const [isMobileDropdown, setIsMobileDropdown] = useState(false);
    const mobileCallback = (isMobileDropdown) => {
        setIsMobileDropdown(isMobileDropdown);
    };
    const { pathname } = window.location;
    const mainClickHandler = () => {
        props.closed && props.closed();
        setIsMobileDropdown(false);
    };

    return (
        <ul className={classes.navList}>
            <li onClick={() => setIsMobileDropdown((prev) => !prev)}>
                <p
                    className={
                        pathname.includes("mobile")
                            ? classes.activeNavItem
                            : classes.navItem
                    }
                >
                    Mobile <IoIosArrowDown className={classes.downIcon} />
                </p>
                {isMobileDropdown && (
                    <Dropdown callbackFromParent={mobileCallback}>
                        <li>
                            <NavLink
                                className={classes.navItem}
                                exact
                                to={`/mobile/premium`}
                                onClick={mainClickHandler}
                            >
                                Premium
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className={classes.navItem}
                                exact
                                to={`/mobile`}
                                onClick={mainClickHandler}
                            >
                                Esencial
                            </NavLink>
                        </li>
                    </Dropdown>
                )}
            </li>
            <li>
                <NavLink
                    className={classes.navItem}
                    onClick={mainClickHandler}
                    activeClassName={classes.activeNavItem}
                    exact
                    to="/home"
                >
                    Home
                </NavLink>
            </li>
            <li>
                <NavLink
                    className={classes.navItem}
                    onClick={props.closed}
                    activeClassName={classes.activeNavItem}
                    exact
                    to="/travel"
                >
                    Travel
                </NavLink>
            </li>
            <li>
                <a
                    className={classes.navItem}
                    onClick={props.closed}
                    activeClassName={classes.activeNavItem}
                    href="https://portal.tricom.mx"
                >
                    Mi Cuenta
                </a>
            </li>
            {width <= 572 && (
                <>
                    <li>
                        <NavLink
                            className={classes.navItem}
                            onClick={props.closed}
                            activeClassName={classes.activeNavItem}
                            exact
                            to="/contacto"
                        >
                            Contacto
                </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className={classes.navItem}
                            onClick={props.closed}
                            activeClassName={classes.activeNavItem}
                            exact
                            to="/preguntas"
                        >
                            Preguntas
                </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className={classes.navItem}
                            onClick={props.closed}
                            activeClassName={classes.activeNavItem}
                            exact
                            to="/acerca"
                        >
                            Acerca de
                </NavLink>
                    </li>
                    </>
            )}
                </ul>
            );
};

export default NavigationItems;
