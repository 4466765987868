import React from "react";

import classes from "./header.module.scss";
import Logo from "../../UI/Logo/Logo";
import NavigationItems from "../NavigationItems/NavigationItems";
import DrawerToggle from "../SideDrawer/DrawerToggle/DrawerToggle";
import { AiOutlinePhone } from "react-icons/ai";
import { formatPhone } from "utils/format";

const Header = (props) => {
    const phone = '8003239777';
    const formattedPhone = formatPhone(phone);
    return (
        <>
            <div className={classes.phone}>
                <p>
                    <a href={`tel:${phone}`}>
                        <AiOutlinePhone/>
                        {formattedPhone}
                    </a>
                </p>
            </div>
            <header className={classes.header}>
                <DrawerToggle onClick={props.drawerToggleClicked} />
                <Logo />
                <nav className={classes.desktopOnly}>
                    <NavigationItems />
                </nav>
            </header>
        </>
    );
};

export default Header;
