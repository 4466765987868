import React, { useEffect, useState } from "react";

import Header from "../../components/Navigation/header/header";
import Footer from "../../components/Navigation/footer/footer";

import classes from "./layout.module.scss";
import SideDrawer from "../../components/Navigation/SideDrawer/SideDrawer";
import ScrollToTop from "../../components/Navigation/ScrollToTop";

const Layout = (props) => {
    ScrollToTop();
    const [sideDrawerIsVisible, setSideDrawerIsVisible] = useState(false);
   
    const sideDrawerClosedHandler = () => {
        setSideDrawerIsVisible(false);
    };

    const sideDrawerToggleHandler = () => {
        setSideDrawerIsVisible(!sideDrawerIsVisible);
    };

    return (
        <div className={classes.container}>
            <div className={classes.content}>
                <Header drawerToggleClicked={sideDrawerToggleHandler} />
                <SideDrawer
                    open={sideDrawerIsVisible}
                    closed={sideDrawerClosedHandler}
                />
                {props.children}
            </div>
            <Footer />
        </div>
    );
};

export default Layout;
