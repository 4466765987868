import React from "react";
import ReactPixel from "react-facebook-pixel";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import SnackbarProvider from "react-simple-snackbar";

import "./App.css";
import MainNav from "./routes";

import saleReducer from "./store/slices/saleSlice";
import pageReducer from "./store/slices/pageSlice";
import Intercom from "./Intercom";

const store = configureStore({
  reducer: {
    sale: saleReducer,
    page: pageReducer,
  },
  middleware: [...getDefaultMiddleware()],
});

function App() {
  //enable conekta library in 15 seconds
  React.useEffect(() => {
    const timer = setTimeout(() => {
      const script = document.createElement("script");
      script.src = "https://cdn.conekta.io/js/latest/conekta.js";
      script.async = true;
      document.body.appendChild(script);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  //enable Pixel
  ReactPixel.init("225906452505167");

  return (
    <div className="App">
      <Intercom>
        <HelmetProvider>
          <Provider store={store}>
            <SnackbarProvider>
              <MainNav />
            </SnackbarProvider>
          </Provider>
        </HelmetProvider>
      </Intercom>
    </div>
  );
}

export default App;
