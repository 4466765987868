import React from "react";

import classes from "./Logo.module.scss";
import { Link } from "react-router-dom";

const Logo = () => {
    const logo = "https://media.tricom.mx/images/Logo-blanco.png";
    return (
        <Link to="/">
            <img src={logo} alt="Logo" className={classes.logo} />
        </Link>
    );
};

export default Logo;
