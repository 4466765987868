import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes, { ErrorPage } from "./routes";

export default function () {
    return (
        <Switch>
            {routes.map((route, i) => (
                <Route
                    exact
                    key={route.path + i}
                    path={route.path}
                    component={route.component}
                />
            ))}
            <Redirect to="/mobile" />
            <Route component={ErrorPage} />
        </Switch>
    );
}
