import { createSlice } from "@reduxjs/toolkit";
import { getPlans, postProspect } from "../async/pageAsyncThunk";

const initialState = {
    loading: "idle",
    currentRequestId: undefined,
    error: null,
    plans: []
};

const pageSlice = createSlice({
    name: "page",
    initialState: initialState,
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
    },
    extraReducers: {
        //getPlans
        [getPlans.pending]: (state, action) => {
            if (state.loading === "idle") {
                state.loading = "pending";
                state.currentRequestId = action.meta.requestId;
            }
        },
        [getPlans.fulfilled]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.currentRequestId = undefined;
                state.error = null;
                state.plans = action.payload;
            }
        },
        [getPlans.rejected]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.error = action.payload?.detail || action.error.message;
                state.currentRequestId = undefined;
            }
        },
        //postProspect
        [postProspect.pending]: (state, action) => {
            if (state.loading === "idle") {
                state.loading = "pending";
                state.currentRequestId = action.meta.requestId;
            }
        },
        [postProspect.fulfilled]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.currentRequestId = undefined;
                state.error = null;
            }
        },
        [postProspect.rejected]: (state, action) => {
            const { requestId } = action.meta;
            if (
                state.loading === "pending" &&
                state.currentRequestId === requestId
            ) {
                state.loading = "idle";
                state.error = action.payload?.detail || action.error.message;
                state.currentRequestId = undefined;
            }
        },
    },
});

export const { clearError } = pageSlice.actions;

export default pageSlice.reducer;
