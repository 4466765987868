import React from 'react';
import NavigationItems from '../NavigationItems/NavigationItems';
import classes from './SideDrawer.module.scss';
import Backdrop from '../../UI/Backdrop/Backdrop';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const SideDrawer = (props) => {
    let attachedClasses = [classes.SideDrawer, classes.Close];
    if (props.open) {
        attachedClasses = [classes.SideDrawer, classes.Open];
    }
    return (
        <>
            <Backdrop show={props.open} onClick={props.closed}/>
            <div className={attachedClasses.join(' ')}>
                <AiOutlineCloseCircle className={classes.closeIcon} onClick={props.closed} />
                <nav>
                    <NavigationItems
                        closed={props.closed}/>
                </nav>
            </div>
        </>
    );
};

export default SideDrawer;
