import React from "react";
import { IntercomProvider } from "react-intercom-hook";
import ReactPixel from "react-facebook-pixel";

export default function Intercom(props) {
  return (
    <IntercomProvider
      appId={"gy6gaiw6"}
      onShow={() => ReactPixel.track("Contact")}
      autoBoot
    >
      {props.children}
    </IntercomProvider>
  );
}
