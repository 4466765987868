import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";

import conekta from "../../utils/conekta";
import errorHandler from "./errorHandler";

/*
All store or contrata related actions.
*/

//compartCarga
export const getCompartCarga = createAsyncThunk(
  "sale/getCompartCarga",
  async (address, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading } = getState().sale;
    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }
    return await errorHandler(
      Axios.post(`${process.env.REACT_APP_BACKEND_URL}/koonol/compartcarga`, {
        address,
      }),
      rejectWithValue
    );
  }
);

//recarga simple
export const simpleRecharge = createAsyncThunk(
  "sale/simpleRecharge",
  async (
    { msisdn, selectedPlan, card },
    { getState, requestId, rejectWithValue }
  ) => {
    const { loading, currentRequestId, orderType } = getState().sale;
    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }
    let conektaToken;
    if (card) {
      conekta.initConekta();
      conektaToken = await conekta.tokenize(card);
    }
    const body = {
      planId: +selectedPlan.id,
      tokenId: conektaToken?.id || "",
      sellerId: process.env.REACT_APP_SELLER_ID,
      orderType: orderType,
      payMethod: conektaToken ? "card_payment" : "cash_payment",
    };
    try {
      const { data } = await Axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/client/${msisdn}/simple-recharge`,
        body
      );
      return {
        totalPrice: data.total,
        status: data.status,
        message: data.details,
        reference: data.reference,
      };
    } catch (err) {
      if (err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        detail: "Hubo un error al procesar tu solicitud. Contáctanos",
        code: 500,
        slug: "unknown_error",
      });
    }
  }
);
