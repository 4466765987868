import { createSlice } from "@reduxjs/toolkit";
import { getCompartCarga, simpleRecharge } from "../async/saleAsyncThunk";

// Initial State
const initialState = {
  loading: "idle",
  currentRequestId: undefined,
  error: null,
  orderType: null,
  rejectedMessage: null,
  rejectedCounter: 0,
  fulfilled: false,
  paymentType: null,
  selectedPlan: null,
  reference: null,
};

const saleSlice = createSlice({
  name: "sale",
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    clearRejectedMessage: (state) => {
      state.rejectedMessage = null;
    },
    clearRejectedCounter: (state) => {
      state.rejectedCounter = 0;
    },
    setOrderType: (state, action) => {
      state.orderType = action.payload;
    },
    setPaymentType: (state, action) => {
      console.log("Payment", action.payload);
      state.paymentType = action.payload;
    },
  },
  extraReducers: {
    //compartCarga
    [getCompartCarga.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [getCompartCarga.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.currentRequestId = undefined;
        state.error = null;
      }
    },
    [getCompartCarga.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.payload || action.error;
        state.currentRequestId = undefined;
      }
    },
    //recarga simple
    [simpleRecharge.pending]: (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.currentRequestId = action.meta.requestId;
      }
    },
    [simpleRecharge.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.loading === "pending" &&
        state.currentRequestId === requestId &&
        action.payload
      ) {
        state.loading = "idle";
        state.currentRequestId = undefined;
        state.error = null;
        if (state.paymentType === "card") {
          if (action.payload.status === "success") {
            state.fulfilled = true;
          } else if (action.payload.status === "fail") {
            state.rejectedMessage = action.payload.message;
            state.rejectedCounter++;
          }
        } else {
          state.fulfilled = true;
          state.reference = action.payload.reference;
        }
      }
    },
    [simpleRecharge.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.error = action.payload || action.error;
        state.currentRequestId = undefined;
      }
    },
  },
});

export const {
  clearError,
  clearRejectedMessage,
  clearRejectedCounter,
  setPaymentType,
  setOrderType,
} = saleSlice.actions;

export default saleSlice.reducer;
